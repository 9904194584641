
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SEARCH_QUERY_DATA_PROP_OPTIONS, SearchQueryData } from '@/utility/definitions';

/**
 * Base definition for a search query related component.
 */
@Component
export default class SearchQueryBase extends Vue {
  /**
   * Search query data to be used for extracting the current value.
   *
   * @var {SearchQueryData}
   */
  @Prop(SEARCH_QUERY_DATA_PROP_OPTIONS)
  public value!: SearchQueryData;
}
