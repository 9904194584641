import { Schema, Templates } from '@/json';
import { Certificate } from '@govflanders/mbp-admin-panel-shared';
import { v4 as uuid } from 'uuid';

export function getCertificateSchema(): object {
  return Schema.Certificate;
}

export function getCertificateTemplate(): Certificate {
  return Object.assign({ id: uuid() }, Templates.Certificate);
}
