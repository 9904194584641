
import { Component, Prop } from 'vue-property-decorator';
import {
  SEARCH_FILTER_DEFINITIONS_PROP_OPTIONS,
  SearchFilterDefinitions,
  SearchFilterDefinition,
  SearchFilterTypes,
} from '@/utility/definitions';
import SearchQueryBase from './query/SearchQueryBase.vue';
import SearchFilterBase from './filters/SearchFilterBase.vue';
import SearchFilterInput from './filters/SearchFilterInput.vue';
import SearchFilterRadio from './filters/SearchFilterRadio.vue';

/**
 * Generic search filters which uses definitions to render component.
 */
@Component
export default class SearchFilters extends SearchQueryBase {
  /**
   * Optional. Search filters intro title.
   *
   * @var {string|null}
   */
  @Prop(String)
  public title!: string | undefined;

  /**
   * List of search filter definitions.
   *
   * @var {SearchFilterDefinitions}
   */
  @Prop({
    ...SEARCH_FILTER_DEFINITIONS_PROP_OPTIONS,
    required: true,
  })
  public filters!: SearchFilterDefinitions;

  /**
   * Resolve component for given search filter definition.
   *
   * @param {SearchFilterDefinition} filter
   *   Definition of the search filter.
   *
   * @return {typeof SearchFilterBase}
   *   Reference to the component constructor.
   */
  protected resolveComponent(filter: SearchFilterDefinition): typeof SearchFilterBase {
    // Evaluate the type of filter.
    switch (filter.type) {
      case SearchFilterTypes.Radio:
        return SearchFilterRadio;

      default:
        return SearchFilterInput;
    }
  }
}
