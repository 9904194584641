
import { Component, Prop } from 'vue-property-decorator';
import { SearchQueryDataPropertyValue } from '@/utility/definitions';
import SearchQueryModelBase from '../query/SearchQueryModelBase.vue';

/**
 * Base definition for a search filter.
 */
@Component
export default class SearchFilterBase extends SearchQueryModelBase {
  /**
   * Label of the search filter.
   *
   * @var {string}
   */
  @Prop({
    type: String,
    required: true,
  })
  public label!: string;

  /**
   * Unique search filter identifier.
   *
   * @return {string}
   *   Unique identifier for search filter.
   */
  public get id(): string {
    return this.uid;
  }

  /**
   * Get the current filter value.
   *
   * @return {SearchQueryDataPropertyValue}
   *   Current search filter value.
   */
  public get filterValue(): SearchQueryDataPropertyValue {
    // Check whether value is an object.
    if (typeof this.value === 'object') {
      // Get the filter value for given identifier.
      const filterValue = this.value[this.name] || null;
      // Check whether a filter value was resolved.
      if (filterValue) {
        // Use the resolve filter value.
        return filterValue;
      }
    }
    // Fallback to null as default behavior.
    return null;
  }

  /**
   * Set the current filter value.
   *
   * @param {SearchQueryDataPropertyValue} value
   *   Current search filter value.
   */
  public set filterValue(value: SearchQueryDataPropertyValue) {
    // Notify about changed value.
    this.onValueChanged(value);
  }
}
