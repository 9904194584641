
import { debounce } from 'lodash';
import { Component } from 'vue-property-decorator';
import { SearchQueryDataPropertyValue } from '@/utility/definitions';
import SearchFilterBase from './SearchFilterBase.vue';

/**
 * Search filter which uses text input.
 */
@Component
export default class SearchFilterInput extends SearchFilterBase {
  /**
   * Holds the debounce function which will delay the filter update
   * until user stops typing.
   *
   * @var {Function}
   */
  private setFilterValueDelayed = debounce(this.onValueChanged.bind(this), 500);

  /**
   * {@inheritdoc}
   */
  public get filterValue(): SearchQueryDataPropertyValue {
    // Check whether value is an object.
    if (typeof this.value === 'object') {
      // Get the filter value for given identifier.
      const filterValue = this.value[this.name] || null;
      // Check whether a filter value was resolved.
      if (filterValue) {
        // Use the resolve filter value.
        return filterValue;
      }
    }
    // Fallback to null as default behavior.
    return null;
  }

  /**
   * {@inheritdoc}
   */
  public set filterValue(value: SearchQueryDataPropertyValue) {
    // Do not update filter value until user has stopped typing.
    this.setFilterValueDelayed(value);
  }
}
