
import { SEARCH_PAGE_META_DATA_PROP_OPTIONS, SearchPageMetaData } from '@/utility/definitions';
import { Component, Prop } from 'vue-property-decorator';
import SearchQueryModelBase from './query/SearchQueryModelBase.vue';

/**
 * Generic search pager.
 */
@Component
export default class SearchPager extends SearchQueryModelBase {
  /**
   * Search page meta data.
   *
   * @var {SearchPageMetaData}
   */
  @Prop({
    ...SEARCH_PAGE_META_DATA_PROP_OPTIONS,
    required: true,
  })
  public pageMetaData!: SearchPageMetaData;

  /**
   * Get the page size.
   *
   * @return {number}
   */
  public get size(): number {
    return this.pageMetaData.size;
  }

  /**
   * Get the page limit.
   *
   * @return {number}
   */
  public get limit(): number {
    return this.pageMetaData.limit;
  }

  /**
   * Get the total number of items.
   *
   * @return {number}
   */
  public get totalItems(): number {
    return this.pageMetaData.totalItems;
  }

  /**
   * Get the current page offset.
   *
   * @return {number}
   */
  public get currentPage(): number {
    return Math.floor(this.pageMetaData.offset / this.pageMetaData.limit);
  }

  public nextPage(): void {
    this.onValueChanged(this.pageMetaData.offset + this.pageMetaData.limit);
  }

  public previousPage(): void {
    this.onValueChanged(this.pageMetaData.offset - this.pageMetaData.limit);
  }
}
