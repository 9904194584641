
import { Component, Prop, Vue } from 'vue-property-decorator';
import JsonEditor from '@/components/shared/forms/JsonEditor.vue';
import {
  Certificate,
  CityCertificateType,
  CertificateAbout,
  CertificateType,
  SourceType,
  AuthenticationMethod,
} from '@govflanders/mbp-admin-panel-shared';
import { getCertificateSchema } from '@/utility/certificates';
import UuidField from '@/components/shared/forms/UuidField.vue';
import StringList from '@/components/shared/forms/StringList.vue';

@Component({
  components: {
    JsonEditor,
    UuidField,
    StringList,
  },
})
export default class CertificateForm extends Vue {
  public schema: object;

  @Prop({
    type: Object,
    required: false,
  })
  public value: Certificate;

  public categories = [
    {
      key: '0',
      value: 'U & uw gezin',
    },
    {
      key: '1',
      value: 'Woonst & vastgoed',
    },
    {
      key: '3',
      value: 'Voordelen & Belastingen',
    },
    {
      key: '5',
      value: 'Cultuur, sport & vrije tijd',
    },
    {
      key: '6',
      value: 'Strafregister',
    },
  ];

  public cityCertificateTypes = Object.keys(CityCertificateType).filter(i => isNaN(Number(i)));

  public aboutWhomTypes = Object.keys(CertificateAbout)
    .filter(i => isNaN(Number(i)))
    .map(key => ({
      key: CertificateAbout[key],
      value: key,
    }));

  public certificateTypes = Object.keys(CertificateType)
    .filter(i => isNaN(Number(i)))
    .map(key => ({
      key: CertificateType[key],
      value: key,
    }));

  public sourceTypes = Object.keys(SourceType)
    .filter(i => isNaN(Number(i)))
    .map(key => ({
      key: SourceType[key],
      value: key,
    }));

  public authenticationMethodTypes = Object.keys(AuthenticationMethod)
    .filter(i => isNaN(Number(i)))
    .map(key => ({
      key: AuthenticationMethod[key],
      value: key,
    }));

  public created() {
    this.schema = getCertificateSchema();
  }

  public get currentValue(): Certificate {
    return this.value;
  }

  public set currentValue(value: Certificate) {
    this.$emit('input', value);
  }

  public setNumber(val, field): void {
    this.value[field] = Number(val);
  }

  public setCategoryType(val: string): void {
    this.value.category = this.categories.find(el => el.value === val);
  }

  public addDeliveringService(): void {
    this.value.deliveringService = {
      key: null,
      value: null,
    };
  }
}
