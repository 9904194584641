
import { Component, Prop } from 'vue-property-decorator';
import SearchQueryBase from './SearchQueryBase.vue';
import { SearchQueryDataPropertyValue } from '@/utility/definitions';

/**
 * Base definition for a search query related component.
 */
@Component
export default class SearchQueryModelBase extends SearchQueryBase {
  /**
   * Name of the search query model.
   *
   * @var {string}
   */
  @Prop({
    type: String,
    required: true,
  })
  public name!: string;

  /**
   * Notify parent component about updated search query for given value.
   *
   * @param {SearchQueryDataPropertyValue} value
   *   Updated search query model value.
   */
  protected onValueChanged(value: SearchQueryDataPropertyValue) {
    // Create a clone of the existig value object.
    const query = Object.assign({}, this.value, { [this.name]: value || null });
    // Notify parent about changed value.x
    this.$emit('input', query);
  }
}
