
import { Component, Prop } from 'vue-property-decorator';
import {
  SEARCH_FILTER_DEFINITIONS_PROP_OPTIONS,
  SEARCH_PAGE_META_DATA_PROP_OPTIONS,
  SearchFilterDefinitions,
  SearchPageMetaData,
  SearchQueryData,
} from '@/utility/definitions';
import SearchQueryBase from './query/SearchQueryBase.vue';
import SearchFilters from './SearchFilters.vue';
import SearchPager from './SearchPager.vue';

/**
 * Generic search component which provides support filters and pager.
 */
@Component({
  components: {
    SearchFilters,
    SearchPager,
  },
})
export default class SearchPageTemplate extends SearchQueryBase {
  /**
   * Optional. Search filters intro title.
   *
   * @var {string|null}
   */
  @Prop(String)
  public filtersTitle!: string | undefined;

  /**
   * List of search filter definitions.
   *
   * @var {SearchFilterDefinitions}
   */
  @Prop({
    ...SEARCH_FILTER_DEFINITIONS_PROP_OPTIONS,
    required: true,
  })
  public filters!: SearchFilterDefinitions;

  /**
   * Name of the page offset parameter.
   *
   * @var {string}
   */
  @Prop({
    type: String,
    required: true,
  })
  public pageQueryName!: string;

  /**
   * Search page meta data.
   *
   * @var {SearchPageMetaData}
   */
  @Prop(SEARCH_PAGE_META_DATA_PROP_OPTIONS)
  public pageMetaData!: SearchPageMetaData | undefined;

  /**
   * Offset which should be used when calculating the page numbers.
   *
   * @var {number}
   */
  @Prop({
    type: Number,
    required: true,
    validator(value: number): boolean {
      return Number.isInteger(value) && value >= 0;
    },
  })
  public pageOffset!: number;

  /**
   * Notify about query data changes from search filters.
   *
   * @param {SearchQueryData} query
   *   An object which represents the search query data.
   */
  protected onSearchFiltersChanged(query: SearchQueryData) {
    // Reset search pager when filters are changed to reset search results
    // to the initial page. We use 'null' to disable the page offset query
    // and allow default logic to be executed.
    const actualQuery = Object.assign({}, query, { [this.pageQueryName]: null });
    // Notify parent component about model changes.
    this.$emit('input', actualQuery);
  }

  /**
   * Notify about query data changes from search pager.
   *
   * @param {SearchQueryData} query
   *   An object which represents the search query data.
   */
  protected onSearchPagerChanged(query: SearchQueryData) {
    this.$emit('input', query);
  }
}
