
import { Component, Prop } from 'vue-property-decorator';
import SearchFilterBase from './SearchFilterBase.vue';
import { SEARCH_FILTER_OPTIONS_PROP_OPTIONS, SearchFilterOptions } from '@/utility/definitions';

/**
 * Search filter which uses radio buttons.
 */
@Component
export default class SearchQueryFilterRadio extends SearchFilterBase {
  /**
   * List of available search filter options.
   *
   * @var {SearchFilterOptions}
   */
  @Prop({
    ...SEARCH_FILTER_OPTIONS_PROP_OPTIONS,
    required: true,
  })
  public options!: SearchFilterOptions;
}
