
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Role } from '@govflanders/mbp-admin-panel-shared';

@Component
export default class RoleGuard extends Vue {
  @Getter('app/userRoles')
  private userRoles: string[] | undefined;

  public isAccessibleForUser(requiredRoles: string[] | undefined): boolean {
    return (
      this.userRoles && requiredRoles && requiredRoles.every(role => this.userRoles.includes(role))
    );
  }

  public get userHasWritePermission(): boolean {
    return this.isAccessibleForUser([Role.Write]);
  }
}
